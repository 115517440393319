export const BASE_URL ='http://127.0.0.1:8000';
// export const BASE_URL ='https://api.lowkalo.com';
export const VIDEOS_URL = '/api/videos';
export const SERVICE_URL = '/api/services';
export const SOLUATIONS_URL = '/api/soluations';
export const USERS_URL = '/api/users';
export const COURSE_URL = '/api/course';
export const COUPONS_URL = '/api/coupons';


